<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Set yourself up for success with notification settings
      </span></template
    >
    <div class="container-fluid">
      <b-row class="my-4">
        <h3>Your Email Notification Settings</h3>
      </b-row>
      <b-row class="my-4">
        <b-col>
          <h4>Communication from GoFindBuild</h4>
        </b-col>
        <b-col>
          <b-form-checkbox
            @change="updateProfile"
            v-model="notifications.communication"
            switch
            id="communication"
            class="actively-hiring-switch"
          ></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col>
          <h4>Hiring Notifications</h4>
        </b-col>
        <b-col>
          <b-form-checkbox
            @change="updateProfile"
            v-model="notifications.hiring"
            switch
            id="hiring"
            class="actively-hiring-switch"
          ></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="my-4" v-if="$store.state.userProfile.type === 'provider'">
        <b-col>
          <h4>Project Notifications</h4>
        </b-col>
        <b-col>
          <b-form-checkbox
            @change="updateProfile"
            v-model="notifications.project"
            switch
            id="project"
            class="actively-hiring-switch"
          ></b-form-checkbox>
        </b-col>
      </b-row>
      <!--b-row class="my-4">
        <b-col>
          <h4>Connection Notifications</h4>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="notifications.connection"
            switch
            id="connection"
            class="actively-hiring-switch"
          ></b-form-checkbox>
        </b-col>
      </b-row-->
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
  data() {
    return {
      notifications: {
        communication:
          this.$store.state.userProfile.notifications?.communication,
        hiring: this.$store.state.userProfile.notifications?.hiring,
        project: this.$store.state.userProfile.notifications?.project,
        connection: this.$store.state.userProfile.notifications?.connection,
      },
    }
  },
  methods: {
    updateProfile() {
      let profile = this.$store.state.userProfile
      console.log(this.notifications)
      profile.notifications = this.notifications
      this.$store.dispatch(
        `api/${
          profile.type == "jobSeeker" ? "updateProfile" : "updateCompanyProfile"
        }`,
        {
          profileId: profile._id,
          data: { ...profile },
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.actively-hiring-label {
  white-space: nowrap;
}
</style>
