<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Edit billing details and view past purchases
      </span></template
    >
    <b-container class="p-4">
      <b-row>
        <b-col cols="12" lg="7">
          <!--h1 class="mb-2">Billing details</h1-->
          <form
            role="form"
            method="post"
            @submit.prevent="$emit('handle-submit')"
          >
            <!--b-form-group label="Name">
              <base-input
                class="mb-4"
                v-model="fields.name"
                placeholder="Name"
                rows="6"
              ></base-input>
            </b-form-group>
            <b-form-group label="Address">
              <base-input
                class="mb-4"
                v-model="fields.address"
                placeholder="Address"
                rows="6"
              ></base-input>
            </b-form-group-->
            <!--b-row>
              <b-col lg="4">
                <b-form-group label="City">
                  <base-input
                    class="mb-4"
                    v-model="fields.city"
                    placeholder="City"
                    rows="3"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="State">
                  <base-input
                    class="mb-4"
                    v-model="fields.state"
                    placeholder="State"
                    rows="3"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Zip">
                  <base-input
                    class="mb-4"
                    v-model="fields.zip"
                    placeholder="Zip"
                    rows="3"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row-->
            <b-row>
              <b-col lg="8">
                <h1 class="mb-2">Payment Information</h1>
              </b-col>
              <!--b-button
              v-b-modal.add-new-card
              variant="link"
              class="orange-clr"
              @click="setupIntent"
              >Change Card</b-button
            -->
              <add-card-modal></add-card-modal>
            </b-row>
            <b-row>
              <payment-method-display
                v-for="p in paymentMethods"
                :key="p.id"
                :paymentMethod="p"
              ></payment-method-display>
              <div class="row" v-if="paymentMethods.length == 0">
                <div
                  v-if="loadingMethods"
                  class="text-center align-items-center"
                  style="margin: auto"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
              <!--b-col lg="6">
                <b-form-group label="Card Number">
                  <base-input
                    class="mb-4"
                    v-model="cardNumber"
                    placeholder="Card Number"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Expiration Date">
                  <base-input
                    class="mb-4"
                    v-model="expiration"
                    placeholder="Expiration Date"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col-->
            </b-row>
          </form>
        </b-col>
        <b-col cols="12" lg="5">
          <h3 class="mb-2">Past Purchases</h3>
          <ul>
            <li v-for="p in pastPurchases" :key="p.id">
              {{ new Date(p.created * 1000).toLocaleDateString() }}-{{
                p.metadata.product_name
              }}
              : ${{ p.amount / 100 }}
            </li>
            <div class="row" v-if="pastPurchases.length == 0">
              <div
                v-if="loadingData"
                class="text-center align-items-center"
                style="margin: auto"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <!--b-modal id="add-new-card" hide-footer>
      <h1>Add New Card</h1>
      <form class="mt-4">
        <b-row class="mt-4">
          <div id="payment-element" class="payment-element">
          </div>
        </b-row>
        <div class="text-right">
        <b-button
          class="btn btn-orange btn-rounded"
          @click="addNewCard"
          >Submit</b-button
        >
        </div>
       </form>
    </b-modal-->
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import stripeMixin from "@/mixins/stripeMixin"
import addCardModal from "@/components/Payment/AddCardModal"
import PaymentMethodDisplay from "@/components/Payment/PaymentMethodDisplay.vue"
export default {
  name: "Billing",
  mixins: [stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    addCardModal,
    PaymentMethodDisplay,
  },
  data() {
    return {
      paymentMethods: [],
      selectedPaymentMethod: null,
      fields: {
        name: this.$store.state.userProfile.name,
        address: this.$store.state.userProfile.address,
        city: this.$store.state.userProfile.city,
        state: this.$store.state.userProfile.state,
        zip: this.$store.state.userProfile.zip,
      },
      cardNumber: null,
      expiration: null,
      customer: "",
      pastPurchases: [],
      loadingData: false,
      loadingMethods: false,
    }
  },
  async created() {
    await this.getPaymentMethods()
    await this.getCharges(this.customer)
  },
  methods: {
    async getPaymentMethods() {
      try {
        this.loadingMethods = true
        const response = await this.$store.dispatch("api/getPaymentMethods")
        if (response.data.errors) {
          this.logDevError(response.data?.errors?.[0])
        } else {
          const profile = this.$store.state.userProfile
          const defaultPaymentMethodId = profile?.defaultPaymentMethod
          const paymentMethods = response.data
          this.paymentMethods = paymentMethods ?? []
          const currentPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === this.currentPaymentMethod
          )
          const defaultPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
          )
          const selectedPaymentMethod =
            currentPaymentMethod ??
            defaultPaymentMethod ??
            paymentMethods[0] ??
            null
          this.selectedPaymentMethod = selectedPaymentMethod
          this.cardNumber =
            "Ending in " + this.selectedPaymentMethod?.card?.last4
          this.expiration =
            this.selectedPaymentMethod?.card?.exp_month +
            "/" +
            this.selectedPaymentMethod?.card?.exp_year
          this.customer = this.selectedPaymentMethod?.customer
        }
        this.loadingMethods = false
      } catch (e) {
        this.loadingMethods = false
        console.error(e)
      }
    },
    async getCharges() {
      try {
        this.loadingData = true
        let pastPurchases = await this.$store.dispatch("api/getPaymentHistory")
        this.pastPurchases = pastPurchases.data
        this.loadingData = false
      } catch (e) {
        this.loadingData = false
        console.error(e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.payment-method {
  width: 100%;
  margin-top: 12px;
}
</style>
