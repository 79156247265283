<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        You are reviewing a job application by {{ profile.name }} for
        {{ job.name }}
      </span></template
    >
    <template v-slot:actions-right>
      <b-button
        variant="danger"
        @click="handleEmployeeDecline"
        class="mr-4 rounded-lg"
        >Decline</b-button
      >
      <b-button
        variant="success"
        @click="handleEmployeeConnect"
        class="rounded-lg"
        ><b-spinner v-if="loading" small class="mr-2" />
        Accept
      </b-button>
    </template>
    <profile></profile>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import messagesMixin from "@/mixins/messagesMixin"
import stripeMixin from "@/mixins/stripeMixin"
import profile from "@/api/profile"

export default {
  mixins: [messagesMixin, stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
  },
  props: {
    profileId: String,
    jobId: String,
  },
  data() {
    return {
      profile: null,
      loading: false,
      job: null,
    }
  },
  created() {
    this.fetchProfile()
    this.fetchJob()
  },
  methods: {
    async fetchProfile() {
      try {
        const { data } = await this.$store.dispatch("api/getProfile", {
          profileId: this.profileId,
        })
        this.profile = data
      } catch (error) {
        this.showErrorMessage(
          error,
          "We couldn't retrieve the data for this profile."
        )
      }
    },
    async fetchJob() {
      try {
        const data = await this.$store.dispatch("jobs/dispatchFetchJobById", {
          id: this.jobId,
        })
        if (data.error) {
          this.$router.push("/dashboard")
          return
        }
        this.job = data
      } catch (error) {
        console.error(error)
      }
    },
    async handleEmployeeConnect() {
      console.log("handleEmployeeConnect")
      let id = this.jobId
      let type = "job-application"
      await profile.grantProfileAccess(this.profileId, id, type)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/dashboard")
    },
    async handleEmployeeDecline() {
      console.log("handleEmployeeDecline")
      let id = this.jobId
      let type = "job-application"
      await profile.rejectProfileAccess(this.profileId, id, type)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/dashboard")
    },
  },
}
</script>

<style scoped></style>
