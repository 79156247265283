<template>
  <div>Admin Page</div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  async created() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
