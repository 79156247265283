<template>
  <b-modal
    hide-footer
    dialog-class="modal-no-padding"
    id="public-status-modal"
    :title="title"
    @hide="handleModalHide"
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title font-26-900">{{ title }}</h5>
      <button type="button" class="close" @click="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <p class="my-4 ml-4">
      {{ body }}
    </p>
    <div>
      <div class="w-100">
        <b-button
          @click="handleClick(true)"
          variant="success"
          size="lg"
          class="rounded-lg float-right mb-4 mr-3"
        >
          YES
        </b-button>
        <b-button
          @click="handleCancel()"
          variant="primary"
          size="lg"
          class="rounded-lg float-right mb-4 mr-3"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "public-status-modal",
  props: {
    title: {
      type: String,
      default: "Ready to Receive Offers?",
    },
    body: {
      type: String,
      default:
        "Businesses are prepared to invest in contacting you, please only select YES below if you are prepared to receive potential job offers, schedule interviews, and make a career transition",
    },
  },
  methods: {
    handleClick(value) {
      this.$emit("update-status", value)
    },
    handleCancel() {
      this.$bvModal.hide("public-status-modal")
      window.location.reload()
    },
    handleModalHide() {
      this.$emit("modal-hide")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  & .modal-header {
    & .close span {
      font-size: 2.5rem;
    }
  }
}
</style>
