<template>
  <div class="dashboard-wrapper">
    <div class="pb-5 dashboard-content">
      <div class="dashboard-component stats-wrapper">
        <div class="stats-inner-wrapper">
          <span class="title">Success Center</span>
          <template v-for="(item, i) in SuccessCenterItems">
            <SuccessCenterItem
              :key="i"
              :level="item.level"
              :title="item.title"
              :link="item.actionLink"
              :description="item.description"
            />
          </template>
        </div>
        <div
          class="stats-inner-wrapper"
          v-if="$store.state.userProfile.type == 'jobSeeker'"
        >
          <StatisticsDisplay
            :stats="opportunitiesCount"
            :title="'Opportunities'"
            icon="opportunities"
            :iconColor="'#1CD47E'"
            :iconBackground="'#B8FADA'"
          />
          <StatisticsDisplay
            :stats="appliedJobsCount"
            :title="'Applied'"
            icon="applied"
            :iconColor="'#0095E2'"
            :iconBackground="'#AFE4FF'"
          />
          <StatisticsDisplay
            :stats="jobMatchesCount"
            :title="'Matches'"
            icon="matches"
            :iconColor="'#F77F00'"
            :iconBackground="'#FFD99B'"
          />
        </div>
        <div class="stats-inner-wrapper" v-else>
          <StatisticsDisplay
            :stats="applicationsCount"
            :title="'Applications'"
            icon="opportunities"
            :iconColor="'#1CD47E'"
            :iconBackground="'#B8FADA'"
          />
          <StatisticsDisplay
            :stats="sentJobsCount"
            :title="'Job Posts Sent'"
            icon="applied"
            :iconColor="'#0095E2'"
            :iconBackground="'#AFE4FF'"
          />
          <StatisticsDisplay
            :stats="matchesCount"
            :title="'Total Matches'"
            icon="matches"
            :iconColor="'#F77F00'"
            :iconBackground="'#FFD99B'"
          />
        </div>
      </div>

      <div class="dashboard-component">
        <InterestedCategories :profile="$store.state.userProfile" />
      </div>

      <div
        class="dashboard-component"
        v-if="$store.state.userProfile.type == 'jobSeeker'"
      >
        <JobOpportunities :opportunities="opportunities" />
      </div>
      <div class="dashboard-component" v-else>
        <JobActivities :activities="activities" />
      </div>
    </div>
    <dashboard-profile-modal>
      <candidate-profile-create
        v-if="edit === 'candidate'"
        @close-modal="closeProfileModal"
        :profile="profile"
      />
      <company-profile-create
        v-if="edit && edit !== 'candidate'"
        @close-modal="closeProfileModal"
        :profile="profile"
      />
    </dashboard-profile-modal>
  </div>
</template>
<script>
import DashboardProfileModal from "@/components/Profile/DashboardProfileModal"
import CandidateProfileCreate from "@/components/Profile/Candidate/CandidateProfileCreate"
import CompanyProfileCreate from "@/components/Profile/Company/CompanyProfileCreate"
//import SkilledTrade from "@/components/SkilledTrade"
import messagesMixin from "@/mixins/messagesMixin"
import StatisticsDisplay from "@/components/StatisticsDisplay"
import JobOpportunities from "@/components/JobOpportunities"
import InterestedCategories from "@/components/InterestedCategories"
import SuccessCenterItem from "@/components/SuccessCenterItem.vue"
import JobActivities from "@/components/JobActivities.vue"

export default {
  name: "Dashboard",
  mixins: [messagesMixin],
  components: {
    DashboardProfileModal,
    CandidateProfileCreate,
    CompanyProfileCreate,
    //    SkilledTrade,
    StatisticsDisplay,
    JobOpportunities,
    InterestedCategories,
    SuccessCenterItem,
    JobActivities,
  },
  data() {
    return {
      completed: "",
      edit: null,
      jobList: this.$store.state.jobs.list,
      jobSeekersList: [],
      projectList: [],
      trainingList: [],
      dataLoaded: false,
      trainingLoaded: false,
      projectsLoaded: false,
      tradeCategories: [],
      employeeSkillsNeeded: [],
      showCompletedStatus: true,
      opportunitiesCount: 0,
      appliedJobsCount: 0,
      jobMatchesCount: 0,
      sentJobsCount: 0,
      applicationsCount: 0,
      matchesCount: 0,
      limit: 100,
      currentPage: 1,
      opportunities: [],
      SuccessCenterItems: [],
      activities: [],
    }
  },
  computed: {
    profile() {
      return this.$store.state.userProfile
    },
    profileType() {
      return this.$store.state.profileType
    },
  },
  mounted() {
    if (this.$store.state.userProfile.type == "jobSeeker") {
      this.getFeaturedJobs()
    } else {
      this.getFeaturedJobSeekers()
      this.getFeaturedProjects()
      this.getCompanyStats()
      this.getCompanyActivities()
      if (!this.$store.state.userProfile.provider.isVendor) {
        this.tradeCategories =
          this.$store.state.userProfile.selfPerformWorkCategories || []
      }
      this.employeeSkillsNeeded =
        this.$store.state.userProfile.employeeSkillsNeeded || []
    }
  },
  created() {
    this.getProfileCompleted()
    this.getSuccessCenterItems()

    if (this.$store.state.userProfile.type == "jobSeeker") {
      this.getOpportunities()
      this.getAppliedJobs()
      this.getJobMatches()
    }
  },
  methods: {
    async getFeaturedJobs() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          /*
          this.$store.dispatch("api/updateProfile", {
            profileId,
            data: { lastActivity: new Date() },
          })
          */
          const data = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.$store.state.userProfile.zip,
            briefing: false,
            limit: 10,
            page: 1,
          })
          this.jobList = data.data.result
          this.dataLoaded = true
          const data2 = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.$store.state.userProfile.zip,
            briefing: false,
            willingToProvidePaidTraining: true,
            limit: 10,
            page: 1,
          })
          this.trainingList = data2.data.result
          this.trainingLoaded = true
          //if (data?.completed) this.completed = data.completed
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    async getFeaturedJobSeekers() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        const date = new Date()
        const daysAgo = new Date(date.getTime())
        daysAgo.setDate(date.getDate() - 3)
        //if (zip && position) {
        const zip = this.$store.state.userProfile.zip
        try {
          const list = await this.$store.dispatch("api/searchUsersByParams", {
            filters: {
              zip,
              radius: 50,
            },
            mongoFilters: {
              type: "jobSeeker",
              public: true,
              $or: [
                {
                  "jobSeeker.lastPriorityAccess": {
                    $lt: daysAgo.toISOString(),
                  },
                },
                {
                  $and: [
                    { "jobSeeker.hasHandsOnExperience": false },
                    { "jobSeeker.hasFormalTraining": false },
                  ],
                },
              ],
              // "jobSeeker.hasHandsOnExperience": false,
            },
          })
          this.jobSeekersList = list.data
          this.dataLoaded = true
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    async getProfileCompleted() {
      const profileId = this.$store.state.userProfile?._id
      const apiUrl =
        this.$store.state.userProfile.type == "jobSeeker"
          ? "api/getProfileCompleted"
          : "api/getCompanyProfileCompleted"
      if (profileId) {
        try {
          const { data } = await this.$store.dispatch(apiUrl, { profileId })
          if (data?.decimal)
            this.completed = Math.ceil(data.decimal * 100).toFixed(0)
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    async getFeaturedProjects() {
      const list = await this.$store.dispatch("api/searchProjectsByZip", {
        zip: this.$store.state.userProfile.zip,
        // industry: categories,
      })
      //console.log("LIST", list)
      this.projectList = list.data
      this.projectsLoaded = true
    },
    goToJob(id) {
      this.$router.push("/jobs/" + id)
    },
    handleEditProfile() {
      this.$bvModal.show("dashboard-profile-modal")
      this.edit = this.profileType
    },
    closeProfileModal() {
      this.$bvModal.hide("dashboard-profile-modal")
      this.edit = null
    },
    async getOpportunities() {
      const opportunities = await this.$store.dispatch("api/getOpportunities", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.opportunities = opportunities.data.results
      this.opportunitiesCount = opportunities.data.total
    },
    async getCompanyStats() {
      let stats = await this.$store.dispatch("api/getCompanyStats")
      this.matchesCount = stats.data.matchesCount
      this.applicationsCount = stats.data.jobApplications
      this.sentJobsCount = stats.data.sentJobsCount
    },
    async getCompanyActivities() {
      let data = await this.$store.dispatch("api/getCompanyActivities")
      this.activities = data.data
    },
    async getAppliedJobs() {
      const applies = await this.$store.dispatch("api/getAppliedJobs", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.appliedJobsCount = applies.data.total
    },
    async getJobMatches() {
      const matches = await this.$store.dispatch("api/getJobMatches", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.jobMatchesCount = matches.data.total
    },
    async getSuccessCenterItems() {
      const apiUrl =
        this.$store.state.userProfile.type == "jobSeeker"
          ? "api/getSuccessCenterItems"
          : "api/getCompanySuccessCenterItems"
      const result = await this.$store.dispatch(apiUrl, {
        profileId: this.$store.state.userProfile._id,
      })
      this.SuccessCenterItems = result.data
    },
  },
}
</script>
<style lang="scss">
$blue-text-color: var(--gfb-blue-brand-500, #0095e2);

.progress-profile {
  border-radius: 1.375rem;
}
.trade-categories-list {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;

  .trade-category {
    margin-right: 10px;
    margin-top: 10px;
  }
}

.header-info {
  margin-bottom: 20px;
}

.heading-label {
  font-size: 20px;
  font-weight: 700;
  margin-top: revert;
}

.see-more {
  white-space: nowrap;
}

.see-more-wrapper {
  padding: 0;
}

.profile-completion-status {
  display: flex;
  padding: 20px 24px 24px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $blue-text-color;
  background: var(--White, #fff);
  position: relative;

  .img {
    margin-right: 20px;
  }

  p {
    margin-bottom: 10px;
    line-height: 140%;
  }

  p.text-highlighted {
    color: var(--gfb-blue-brand-700, #00527c);
    font-size: 18px;
    font-weight: 600;
  }

  p.text-status-info {
    color: var(--gfb-blue-brand-700, #00527c);
    font-weight: 400;
  }

  .btn {
    min-width: 112px;
    padding: 8px 16px;
    background: $blue-text-color;
    color: #ffffff;
    margin-top: 15px;

    &:hover {
      background: $blue-text-color !important;
    }
  }

  .close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    i {
      height: 24px;
      width: 24px;
      font-size: 24px;
      color: #b0b9c9;

      &::before {
        -webkit-text-stroke: 2px white;
      }
    }
  }
}

.dashboard-content {
  margin: 0 40px;
}

.dashboard-component {
  margin-top: 24px;
}

.stats-wrapper {
  display: flex;
  justify-content: space-between;

  .stats-inner-wrapper {
    width: 49%;
    border-radius: 12px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 32px 24px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;

    .title {
      color: var(--Gray-900, #343946);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    .stats-display {
      width: 100%;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .stats {
        padding: 45px;
      }

      svg,
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
}
</style>
