<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Find the skilled workers, trade partners, and vendors you've connected
        with.
      </span></template
    >
    <b-row class="mb-4">
      <b-col v-if="$store.state.userProfile.type != 'jobSeeker'">
        Sort by:
        <b-button-group>
          <b-button
            :class="{
              'btn-active': filter === 'jobSeeker',
              'btn-default': filter !== 'jobSeeker',
            }"
            variant="warning"
            @click="filter = 'jobSeeker'"
            >Job Seekers</b-button
          >
          <b-button
            :class="{
              'btn-active': filter === 'provider',
              'btn-default': filter !== 'provider',
            }"
            variant="warning"
            @click="filter = 'provider'"
            >Companies</b-button
          >
          <b-button
            :class="{
              'btn-active': filter === 'vendor',
              'btn-default': filter !== 'vendor',
            }"
            variant="warning"
            @click="filter = 'vendor'"
          >
            Vendors</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
    <div class="row" v-if="filter == 'jobSeeker'">
      <div v-for="s in jobSeekers" :key="s._id" class="col-lg-4">
        <employee-card :seekerId="s._id" />
      </div>
    </div>
    <div class="row" v-else-if="filter == 'provider'">
      <div v-for="c in companies" :key="c._id" class="col-lg-4">
        <company-card :companyId="c._id" />
      </div>
    </div>
    <div class="row" v-else-if="filter == 'vendor'">
      <div v-for="c in vendors" :key="c._id" class="col-lg-4">
        <company-card :companyId="c._id" />
      </div>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import EmployeeCard from "@/components/Cards/EmployeeCard"
import CompanyCard from "@/components/Cards/CompanyCard"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    EmployeeCard,
    CompanyCard,
  },
  data() {
    return {
      connections: this.$store.state.userProfile.profilesRequested,
      jobSeekers: [],
      companies: [],
      vendors: [],
      filter:
        this.$store.state.userProfile.type != "jobSeeker"
          ? "jobSeeker"
          : "provider",
    }
  },
  methods: {
    async getRequestedProfiles() {
      try {
        /*
        const profileId = this.$store.state.userProfile?._id
        this.$store.dispatch("api/updateProfile", {
          profileId,
          data: { lastActivity: new Date() },
        })
        */
        const data = await this.$store.dispatch("api/getRequestedProfiles")
        for (let d of data.data) {
          if (d.type == "jobSeeker") {
            this.jobSeekers.push(d)
          } else if (d.type == "provider" && !d.provider.isVendor) {
            this.companies.push(d)
          } else {
            this.vendors.push(d)
          }
        }
      } catch (error) {
        console.log("error", error)
      }
    },
    async getGrantedProfiles() {
      try {
        const data = await this.$store.dispatch("api/getGrantedProfiles")
        for (let d of data.data) {
          if (d.type == "jobSeeker") {
            this.jobSeekers.push(d)
          } else if (d.type == "provider") {
            this.companies.push(d)
          } else {
            this.vendors.push(d)
          }
        }
      } catch (error) {
        console.log("error", error)
      }
    },
  },
  mounted() {
    this.getRequestedProfiles()
    this.getGrantedProfiles()
  },
}
</script>

<style scoped lang="scss">
.btn-active {
  background: #f77f00;
  color: white;
}
.btn-default {
  background: rgba(123, 128, 157, 0.15);
  border-color: transparent;
  color: #555b79;
}
</style>
