<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left>
      <span class="d-flex align-items-center">
        <h2>Job Seekers</h2>
      </span>
    </template>
    <template v-slot:actions-right>
      <vue-json-to-csv
        :json-data="jobSeekers"
        :labels="dataLabels"
        :csv-title="'Jobseekers'"
      >
        <default-button :labelText="'Export CSV'" />
      </vue-json-to-csv>
    </template>
    <template v-if="jobSeekers.length">
      <v-client-table
        :data="jobSeekers"
        :columns="seekerColumns"
        :options="options"
      >
        <template slot="actions" slot-scope="props" class="cursor-pointer">
          <DefaultButton
            :labelText="'Delete'"
            @click="handleDeleteModal(props.row.user[0]._id)"
          />
        </template>
      </v-client-table>
      <div class="row pagination">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
    </template>
    <div class="row" v-if="jobSeekers.length == 0">
      <div
        v-if="loading"
        class="text-center align-items-center"
        style="margin: auto"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <h2 class="ml-3">No results. Check back next time!</h2>
      </div>
    </div>
    <DefaultModal
      description="You are about to disable this account. Are you sure you want to do this?"
      title="Delete User"
      @onConfirm="handleRemoveUser(deleteUserId)"
      id="delete-user-modal"
      :showCancel="true"
      :btnClasses="['delete-btn']"
    />
    <ToastMessage ref="toastMessage" />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DefaultButton from "@/components/DefaultButton"
import DefaultModal from "@/components/Modals/DefaultModal"
import user from "@/api/user"
import ToastMessage from "@/components/ToastMessage"

export default {
  name: "Posts",
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
    DefaultModal,
    ToastMessage,
  },
  data() {
    return {
      options: { sortable: ["name", "email", "city", "state"] },
      seekerColumns: ["name", "email", "city", "state", "actions"],
      loading: false,
      jobSeekers: [],
      limit: 20,
      currentPage: 0,
      totalRows: 0,
      pageCount: 0,
      dataLabels: {
        name: { title: "Name" },
        email: { title: "Email" },
        city: { title: "City" },
        state: { title: "State" },
        createdAt: { title: "Created At" },
      },
      deleteUserId: null,
    }
  },
  created() {
    this.getJobSeekers()
  },
  methods: {
    async getJobSeekers() {
      this.loading = true
      try {
        const data = await this.$store.dispatch("api/getJobSeekers", {
          limit: this.limit,
          page: this.currentPage,
        })
        this.jobSeekers = data.data.result
        this.totalRows = data.data.total
        this.pageCount = data.data.pageCount
        this.loading = false
      } catch (error) {
        this.loadingData = false
        console.log("error", error)
        this.logDevError(error)
      }
    },
    goToPage(page) {
      this.currentPage = page
    },
    handleDeleteModal(id) {
      this.deleteUserId = id
      this.$bvModal.show("delete-user-modal")
    },
    async handleRemoveUser(id) {
      try {
        await user.deleteUser(id)
        this.getJobSeekers()
        this.$bvModal.hide("delete-user-modal")
        this.$refs.toastMessage.showToast(
          "User has been deleted successfully",
          "success"
        )
      } catch (error) {
        this.$toast.error(
          error.message || "Something went wrong while deleting the job."
        )
      }
    },
  },
  watch: {
    currentPage: function () {
      this.getJobSeekers()
    },
  },
}
</script>

<style scoped lang="scss">
.btn-active {
  background: #f77f00;
  color: white;
}
.btn-default {
  background: rgba(123, 128, 157, 0.15);
  border-color: transparent;
  color: #555b79;
}

.pagination {
  justify-content: center;
}
.page-item .page-link {
  border-radius: 10px !important;
}
</style>
