<template>
  <div>
    <declined-job v-if="type == 'job'" :job="job"></declined-job>
    <job-application
      v-if="type == 'job-application'"
      :profileId="job.profile"
      :jobId="job.job"
    ></job-application>
  </div>
</template>

<script>
import DeclinedJob from "@/components/Notifications/DeclinedJob"
import JobApplication from "@/components/Notifications/JobApplication"

export default {
  name: "NotificationDetail",
  components: {
    DeclinedJob,
    JobApplication,
  },
  data() {
    return {
      type: "",
      job: {},
    }
  },
  methods: {
    getType() {
      let id = this.$router.currentRoute.params.notificationId
      // Check rejected jobs
      for (let j of this.$store.state.userProfile.profilesRejected) {
        if (j._id == id) {
          this.type = j.type
          this.job = j
          return
        }
      }
      // Check requested
      for (let j of this.$store.state.userProfile.profilesRequested) {
        if (j._id == id) {
          this.type = j.type
          this.job = j
          return
        }
      }
    },
  },
  mounted() {
    this.getType()
  },
}
</script>

<style scoped lang="scss"></style>
