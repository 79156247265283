import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=34162230&scoped=true"
import script from "./Settings.vue?vue&type=script&lang=js"
export * from "./Settings.vue?vue&type=script&lang=js"
import style0 from "./Settings.vue?vue&type=style&index=0&id=34162230&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34162230",
  null
  
)

export default component.exports