<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1"
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor.
      </span></template
    >
    <p class="p-2">Coming soon....</p>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
}
</script>

<style scoped lang="scss"></style>
