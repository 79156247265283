<template>
  <div class="container-fluid">
    <div class="row justify-content-center" v-if="!sendNewJob">
      <div class="col-lg-10 col-xl-8">
        <b-row>
          <h1 class="p-2">
            Sorry, your job briefing was declined by {{ name }}
          </h1>
        </b-row>
        <b-row>
          <p class="p-2">
            You have until {{ ends }} to send another job briefing
          </p>
        </b-row>
        <b-row>
          <b-form-group
            label="Below is a summary of their feedback"
            v-slot="{ ariaDescribedby }"
            class="mb-4 pl-4"
          >
            <b-checkbox
              class="mb-4"
              disabled
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="salary"
            >
              The salary was not competitive or ideal for the position
              requirements
            </b-checkbox>
            <b-checkbox
              class="mb-4"
              disabled
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="benefits"
            >
              The benefits package was not ideal to me
            </b-checkbox>
            <b-checkbox
              class="mb-4"
              disabled
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="requirements"
            >
              The position requirements were not ideal to me
            </b-checkbox>
            <b-checkbox
              class="mb-4"
              disabled
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="location"
            >
              The position location was not ideal to me
            </b-checkbox>
            <b-checkbox
              class="mb-4"
              disabled
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="other"
            >
              Other:
            </b-checkbox>
            <b-form-textarea
              disabled
              v-model="details"
              class="mb-4"
              placeholder="Please describe why this job briefing was not ideal for you"
            ></b-form-textarea>
            <b-button
              variant="secondary"
              @click="sendNewJob = !sendNewJob"
              class="rounded-lg apply-btn"
              >Send New Job Briefing</b-button
            >
          </b-form-group>
        </b-row>
        <b-row>
          <p>
            The competition for skilled workers is challenging and we hope you
            have luck next time!
          </p>
        </b-row>
      </div>
    </div>
    <job-form
      v-if="sendNewJob"
      ref="jobForm"
      :job="jobId"
      :currentView="currentView"
      :loading="jobLoading"
      :cancelButtonTo="backUrl"
      :briefing="briefing"
      :skilledWorker="profile"
      @set-current-view="setCurrentView"
    />
  </div>
</template>

<script>
import JobForm from "@/components/Job/JobForm"

export default {
  name: "DeclinedJob",
  components: {
    JobForm,
  },
  props: {
    job: Object,
  },
  data() {
    return {
      selected: this.job.reason.selected,
      ends: new Date(this.job.ends).toLocaleDateString(),
      details: this.job.reason.details,
      profile: this.job.profile,
      jobId: this.job.job,
      name: "",
      currentView: "edit",
      sendNewJob: false,
      briefing: true,
    }
  },
  methods: {
    async getProfile() {
      try {
        const data = await this.$store.dispatch("api/getProfile", {
          profileId: this.profile,
        })
        this.profile = data.data
        this.name = data.data.name
      } catch (error) {
        console.log("error", error)
      }
    },
  },
  mounted() {
    this.getProfile()
  },
}
</script>

<style scoped lang="scss"></style>
